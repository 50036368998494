import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      Oops! Looks like you have lost
      <br />
      404 error
    </div>
  );
};

export default ErrorPage;
